import { VrtBrandLogoBar } from '../VrtBrandLogoBar/VrtBrandLogoBar'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import Link from 'next/link'

export const Footer = () => {
	return (
		<footer className="mt-auto">
			<div className="container max-w-7xl mx-auto px-6 py-16">
				<div className="flex flex-wrap md:text-left text-center order-first">
					<div className="lg:w-1/4 md:w-1/2 w-full text-max-dark-blue">
						<div className="title-font text-xl font-semibold mb-3">
							Nuttige links
						</div>
						<nav className="list-none mb-10 font-light text-lg">
							<li className="pb-2">
								<Link href={'/partner-worden'}>
									<a className="text-lg font-light hover:underline">
										Partner worden
									</a>
								</Link>
							</li>
							<li className="pb-2">
								<a
									href={
										'https://www.vrt.be/nl/heb-je-een-vraag/'
									}
									target="_blank"
									rel="noreferrer"
									className="inline-flex items-center gap-1 hover:underline"
								>
									Klantendienst{' '}
									<ExternalLinkIcon className={'h-4'} />
								</a>
							</li>
							<li className="pb-2">
								<a
									href={
										'https://www.vrt.be/nl/aanbod/kijk-en-luister/'
									}
									target="_blank"
									rel="noreferrer"
									className="inline-flex items-center gap-1 hover:underline"
								>
									Kijk en luister{' '}
									<ExternalLinkIcon className={'h-4'} />
								</a>
							</li>
							<li className="pb-2">
								<a
									href={
										'https://www.vrt.be/nl/aanbod/toegankelijkheid/'
									}
									target="_blank"
									rel="noreferrer"
									className="inline-flex items-center gap-1 hover:underline"
								>
									Toegankelijkheid{' '}
									<ExternalLinkIcon className={'h-4'} />
								</a>
							</li>
							<li className="pb-2">
								<a
									href={
										'https://www.vrt.be/nl/info/jaarverslag/'
									}
									target="_blank"
									rel="noreferrer"
									className="inline-flex items-center gap-1 hover:underline"
								>
									Jaarverslag{' '}
									<ExternalLinkIcon className={'h-4'} />
								</a>
							</li>
							<li className="pb-2">
								<a
									href={
										'https://www.vrt.be/nl/over-de-vrt/beheersovereenkomst/'
									}
									target="_blank"
									rel="noreferrer"
									className="inline-flex items-center gap-1 hover:underline"
								>
									Beheersovereenkomst{' '}
									<ExternalLinkIcon className={'h-4'} />
								</a>
							</li>
							<li>
								<a
									href={
										'https://www.vlaanderen.be/cjm/nl/media/regelgeving'
									}
									target="_blank"
									rel="noreferrer"
									className="inline-flex items-center gap-1 hover:underline"
								>
									Mediadecreet{' '}
									<ExternalLinkIcon className={'h-4'} />
								</a>
							</li>
						</nav>
					</div>
					<div className="lg:w-1/4 md:w-1/2 w-full text-max-dark-blue">
						<div className="title-font text-xl font-semibold  mb-3">
							Info
						</div>
						<nav
							id={'footer-navigation-id-adobe-consent'}
							className="list-none mb-10 text-lg font-light"
						>
							<li className="pb-2">
								<Link href={'/privacybeleid'}>
									<a className="inline-flex items-center gap-1 hover:underline">
										Privacy
									</a>
								</Link>
							</li>
							<li className="pb-2">
								<a
									href={
										'https://www.vrt.be/nl/info/gebruiksvoorwaarden/'
									}
									className="inline-flex items-center gap-1 hover:underline"
								>
									Gebruiksvoorwaarden
									<ExternalLinkIcon className={'h-4'} />
								</a>
							</li>
							<li className="pb-2">
								<a
									href={
										'https://www.vrt.be/nl/info/bedrijfsgegevens/'
									}
									className="inline-flex items-center gap-1 hover:underline"
								>
									Bedrijfsgegevens
									<ExternalLinkIcon className={'h-4'} />
								</a>
							</li>
							<li className="pb-2">
								<a
									href={'https://www.vrt.be/nl/info/cookies/'}
									className="inline-flex items-center gap-1 hover:underline"
								>
									Cookies
									<ExternalLinkIcon className={'h-4'} />
								</a>
							</li>
						</nav>
					</div>
					<div className="lg:w-2/4 w-full px-4 flex flex-col text-max-dark-blue text-lg">
						<h2 className="title-font font-semibold text-xl mb-3">
							Vlaamse Radio- en Televisieomroeporganisatie
						</h2>
						<span className="font-light">
							Vlaamse Radio- en Televisieomroeporganisatie
						</span>
						<span className="font-light">
							VRT NV van publiek recht
						</span>
						<div className="py-4 flex flex-col font-light">
							<span>Auguste Reyerslaan 52</span>
							<span>1043 Brussel</span>
							<a
								href="https://goo.gl/maps/MgokvQGWi3J8Eyfu9"
								target="_blank"
								rel="noreferrer"
								className="inline-flex items-center gap-1 hover:underline"
							>
								Google Maps
								<ExternalLinkIcon className={'h-4'} />
							</a>
							<span className="pt-4">+32 2 741 31 11</span>
						</div>
					</div>
				</div>
			</div>
			<div className="relative" style={{ backgroundColor: '#F2EFF0' }}>
				<div className="container max-w-7xl px-12 py-6 mx-auto">
					<VrtBrandLogoBar color={'black'} />
				</div>
			</div>
		</footer>
	)
}
