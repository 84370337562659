import Head from 'next/head'

const defaultSeoValues = {
	title: 'Open Media',
	description:
		'Krijg toegang tot clips uit de populairste VRT-programma’s en verrijk je klantenervaring met sterke mediacontent.',
}
const SEO = ({
	title = defaultSeoValues.title,
	description = defaultSeoValues.description,
}): JSX.Element => {
	return (
		<Head>
			<title>{`${title} | VRT Open Media`}</title>
			<meta name="description" content={description} key="description" />
			<link rel="shortcut icon" href="/static/favicon.ico" />
		</Head>
	)
}

export default SEO
