import { VrtBrandLogoBarColor } from './Color'
import { VrtBrandLogoBarBlack } from './Black'
import { VrtBrandLogoBarWhite } from './White'

type Color = 'color' | 'black' | 'white'
export const VrtBrandLogoBar = ({
	color,
	cssClassNames = 'h-3 md:h-5 lg:h-8',
}: {
	color?: Color
	cssClassNames?: string
}): JSX.Element => {
	switch (color) {
		case 'color':
			return <VrtBrandLogoBarColor cssClassNames={cssClassNames} />
		case 'black':
			return <VrtBrandLogoBarBlack cssClassNames={cssClassNames} />
		case 'white':
			return <VrtBrandLogoBarWhite cssClassNames={cssClassNames} />
		default:
			return <VrtBrandLogoBarColor cssClassNames={cssClassNames} />
	}
}
