import VrtNwsLogo from '/public/static/vrt-logos/vrtnws/svg/vrtnws-black.svg'
import VrtMaxLogo from '/public/static/vrt-logos/vrt-max/svg/vrt-max-black.svg'
import Vrt1Logo from '/public/static/vrt-logos/vrt-1/vrt1-black.svg'
import CanvasLogo from '/public/static/vrt-logos/canvas/svg/canvas-black.svg'
import SporzaLogo from '/public/static/vrt-logos/sporza/svg/sporza-black.svg'
import KetnetLogo from '/public/static/vrt-logos/ketnet/svg/ketnet-black.svg'
import KetnetJrLogo from '/public/static/vrt-logos/ketnet-jr/svg/ketnet-jr-black.svg'
import Radio1Logo from '/public/static/vrt-logos/radio1/svg/radio1-black.svg'
import Radio2Logo from '/public/static/vrt-logos/radio2/svg/radio2-black.svg'
import StuBruLogo from '/public/static/vrt-logos/stubru/svg/stubru-black.svg'
import MnmLogo from '/public/static/vrt-logos/mnm/svg/mnm-black.svg'
import KlaraLogo from '/public/static/vrt-logos/klara/svg/klara-black.svg'
export const VrtBrandLogoBarBlack = ({
	cssClassNames = 'h-3 md:h-5 lg:h-8',
}: {
	cssClassNames?: string
}): JSX.Element => {
	return (
		<div className="flex items-center justify-between sm:flex-row">
			<a className="items-center">
				<VrtNwsLogo className={cssClassNames} />
			</a>
			<a className="items-center">
				<VrtMaxLogo className={cssClassNames} />
			</a>
			<a className="items-center">
				<Vrt1Logo className={cssClassNames} />
			</a>
			<a className="items-center">
				<CanvasLogo className={cssClassNames} />
			</a>
			<a className="items-center">
				<SporzaLogo className={cssClassNames} />
			</a>
			<a className="items-center">
				<KetnetLogo className={cssClassNames} />
			</a>
			<a className="items-center">
				<KetnetJrLogo className={cssClassNames} />
			</a>
			<a className="items-center">
				<Radio1Logo className={cssClassNames} />
			</a>
			<a className="items-center">
				<Radio2Logo className={cssClassNames} />
			</a>
			<a className="items-center">
				<StuBruLogo className={cssClassNames} />
			</a>
			<a className="items-center">
				<MnmLogo className={cssClassNames} />
			</a>
			<a className="items-center">
				<KlaraLogo className={cssClassNames} />
			</a>
		</div>
	)
}
